












import { Component, Prop, Vue } from "vue-property-decorator";
import { SuggestionModel } from "@/models/suggestion.model";

@Component
export default class RejectSuggestion extends Vue {

  @Prop()
  public suggestion: SuggestionModel;

  public reason: string;
  private isOk = false;

  public onModalOk() {
    this.isOk = true;
    this.$emit('confirmReject', this.reason)
  }

  public onModalFerme() {
    if(!this.isOk) {
      this.$emit('cancelReject')
      this.isOk = false;
    }
  }
}
