



















import Vue from "vue";
import i18n from "@/i18n";
import { Component, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import firebase from "firebase";

import { GameModel } from "@/models/game.model";
import { GameStatus } from "@/models/game-status.enum";

@Component({})
export default class HomeStreamerPage extends Vue {
  public streamer: string;
  public theme: string;
  private streamerList = ["biajo", "nicroz38", "vega_nosaure"];

  public gamesInProgress: Array<GameModel> = [];

  public fields = [
    {
      key: 'game',
      label: i18n.t('historique.headers.game')
    },
    {
      key: 'original_suggesting_author',
      label: i18n.t('historique.headers.original_suggesting_author')
    }
  ];

  private async loadDatas() {
    this.gamesInProgress = await this.loadGamesForStatus(
      GameStatus.IN_PROGRESS
    );
  }
  public mounted(): void {
    this.loadDatas();
  }
  private async loadGamesForStatus(status: GameStatus): Promise<GameModel[]> {
    const snapshot = await firebase
      .database()
      .ref("/")
      .child(this.streamer)
      .child("games")
      .orderByChild("status")
      .equalTo(status)
      .once("value");

    if (snapshot.val() != null) {
      return Object.entries(snapshot.val()).map((entrie) => {
        return new GameModel(Object.assign({}, { id: entrie[0] }, entrie[1]));
      });
    } else {
      return [];
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(to: Route, from: Route) {
    if (to?.params?.streamer !== from?.params?.streamer) {
      this.streamer = to.params.streamer;

      if(this.streamerList.includes(this.streamer)) {
        this.theme = this.streamer;
      } else {
        this.theme = "default";
      }
    }
  }
}
