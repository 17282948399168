































































import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase";

@Component
export default class Login extends Vue {
  private email = "";
  private password = "";
  public errorMessage = "";
  public showAlert = false;
  public showPassword = false;

  public async logToFirebase(event: any) {
    event.preventDefault();
    await firebase
      .auth()
      .signInWithEmailAndPassword(this.email, this.password)
      .then(() => {
        this.$bvModal.hide("modal-login");
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
          case "auth/user-not-found":
          case "auth/wrong-password":
            this.errorMessage = "email_or_password_invalid";
            break;
          case "auth/user-disabled":
            this.errorMessage = "user_disabled";
            break;
          default:
            this.errorMessage = "generic";
            break;
        }
        this.showAlert = true;
      });
  }

  public onReset(event: any) {
    event.preventDefault();
    this.$bvModal.hide("modal-login");
    this.email = "";
    this.password = "";
  }

  public switchShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
