



















import { Route } from "vue-router";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

@Component({})
export default class Streamer extends Vue {
  public streamer: string;
  public theme: string;
  private streamerList = ["biajo", "nicroz38", "vega_nosaure"];

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(to: Route, from: Route) {
    if (to?.params?.streamer !== from?.params?.streamer) {
      this.streamer = to.params.streamer;
      if(this.streamerList.includes(this.streamer)) {
        this.theme = this.streamer;
      } else {
        this.theme = "default";
      }
    }
  }
}
