








































//VUE
import { Component, Vue } from "vue-property-decorator";

//external
import firebase from "firebase";

//internal
import Login from "@/components/Login.vue";
import ModificationPassword from "@/components/ModificationPassword.vue";

@Component({
  components: {ModificationPassword, Login},
})
export default class Header extends Vue {
  public userEmail: string | null = null;

public streamers: string[] = [];

  public async mounted() {
    this.streamers = (await firebase.database().ref('/streamers').get()).val();
    firebase.auth().onAuthStateChanged((user) => {
      this.userEmail = user != null ? user.email : null;
    });
  }

  public async logoutToFirebase() { 
    await firebase.auth().signOut();
  }
}
