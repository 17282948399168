




















































import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase";

@Component
export default class Login extends Vue {
  private newPassword = "";
  private confirmPassword = "";

  public showPassword = false;
  public errors:string[] = [];

  public init() {
    this.newPassword = '';
    this.confirmPassword = '';
    this.showPassword = false;
    this.errors = [];
  }

  public hideModal(event: any) {
    event.preventDefault();
    this.$bvModal.hide("modal-modification-password");
  }

  public validateBeforeSubmit(event: any) {
    event.preventDefault();
    this.errors = [];    
    if(this.newPassword && this.confirmPassword && this.newPassword === this.confirmPassword) {
      this.modification();
      return true;
    }
    if(!this.newPassword) {
      this.errors.push('new_password_required');
    }
    if(!this.confirmPassword) {
      this.errors.push('confirm_password_required');
    }

    this.errors.push('confirm_password_different');
  }

  private async modification() {
    await firebase.auth().currentUser?.updatePassword(this.newPassword).then(() => {
      this.$bvModal.hide("modal-modification-password");
    }).catch((error) => {
      switch (error.code) {
        case "auth/weak-password":
          this.errors.push('auth/weak-password');
          break;
        default:
          this.errors.push('generic');
          break;
      }
    });
  }
}
