import { render, staticRenderFns } from "./HomeStreamerPage.vue?vue&type=template&id=ed596c46&scoped=true&"
import script from "./HomeStreamerPage.vue?vue&type=script&lang=ts&"
export * from "./HomeStreamerPage.vue?vue&type=script&lang=ts&"
import style0 from "./HomeStreamerPage.vue?vue&type=style&index=0&id=ed596c46&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed596c46",
  null
  
)

export default component.exports