




























































import { Component, Vue, Watch } from "vue-property-decorator";
import firebase from "firebase";
import { SuggestionModel } from "@/models/suggestion.model";
import i18n from "@/i18n";
import { GameModel } from "@/models/game.model";
import { SuggestionStatus } from "@/models/suggestion-status.enum";
import { GameStatus } from "@/models/game-status.enum";
import { UserHelper } from "@/user.helper";
import { UserModel } from "@/models/user.model";
import RejectSuggestion from "@/views/Streamer/components/RejectSuggestion.vue";

@Component({
  components: {RejectSuggestion}
})
export default class SuggestionPage extends Vue {
  public suggestions: Array<SuggestionModel> = [];
  public acceptedSuggestions: Array<SuggestionModel> = [];
  public user: UserModel;
  public statusDisplayed = {
    SUBMITED: "info",
    ACCEPTED: "success",
  };
  public suggestionToReject: SuggestionModel | null;

  public fields = [
    {
      key: "game",
      label: i18n.t("suggestions.headers.game"),
    },
    {
      key: "author",
      label: i18n.t("suggestions.headers.author"),
    },
    {
      key: "status",
      label: i18n.t("suggestions.headers.status"),
    },
  ];

  /**{
      key: "actions",
      label: i18n.t("suggestions.headers.actions"),
    } */
  private streamer: string;
  private firebaseConnexion: firebase.database.Reference;

  public mounted(): void {
    this.streamer = this.$route.params.streamer;

    firebase.auth().onAuthStateChanged(async (user) => {
      this.user = new UserModel({
        email: user?.email,
        twitchName: await UserHelper.getUserTwitchName(user?.uid)
      })
      if (this.isStreamerOfChannel()) {
        this.fields.push({
          key: "actions",
          label: i18n.t("suggestions.headers.actions"),
        });
      }
    });

    this.loadSuggestions();
  }

  public askReasonRejectSuggestion(suggestion: SuggestionModel): void {
    this.suggestionToReject = suggestion;
    this.$bvModal.show('modal-reject-suggestion');
  }

  public rejectSuggestion(reason: string) {
    if (this.suggestionToReject != null) {
      firebase
          .database()
          .ref("/")
          .child(this.streamer)
          .child("suggestions")
          .child(this.suggestionToReject.id)
          .update({ reason: reason, status: "REJECTED" });
    }

    this.suggestionToReject = null;
  }

  public cancelReject() {
    this.suggestionToReject = null;
  }

  public acceptSuggestion(suggestion: SuggestionModel): void {
    firebase
      .database()
      .ref("/")
      .child(this.streamer)
      .child("suggestions")
      .child(suggestion.id)
      .update({ status: "ACCEPTED" });
  }

  public archiveSuggestion(suggestion: SuggestionModel): void {
    firebase
        .database()
        .ref("/")
        .child(this.streamer)
        .child("suggestions")
        .child(suggestion.id)
        .update({ status: "ARCHIVED" });
    firebase
      .database()
      .ref("/")
      .child(this.streamer)
      .child("games")
      .push(
        new GameModel({
          game: suggestion.game,
          original_suggesting_author: suggestion.author,
          status: GameStatus.TODO,
        })
      );
  }

  public isStreamerOfChannel(): boolean {
    return UserHelper.isStreamerOfChannel(this.user, this.streamer);
  }

  @Watch("$route.params.streamer")
  public changeStreamer() {
    this.firebaseConnexion.off();
    this.streamer = this.$route.params.streamer;
    this.loadSuggestions();
  }

  private extractSuggestionValues(snapshot: any): SuggestionModel[] {
    if (snapshot.val() != null) {
      return Object.entries(snapshot.val()).map((entrie) => {
        return new SuggestionModel(
            Object.assign({}, { id: entrie[0] }, entrie[1])
        );
      });
    } else {
      return [];
    }
  }

  private loadSuggestions() {
    this.firebaseConnexion = firebase
      .database()
      .ref("/")
      .child(this.streamer)
      .child("suggestions");
    this.firebaseConnexion
      .orderByChild("status")
      .equalTo(SuggestionStatus.SUBMITED)
      .on("value", (snapshot) => {
        this.suggestions = this.extractSuggestionValues(snapshot);
      });

    this.firebaseConnexion
        .orderByChild("status")
        .equalTo(SuggestionStatus.ACCEPTED)
        .on("value", (snapshot) => {
          this.acceptedSuggestions = this.extractSuggestionValues(snapshot);
        });
  }
}
