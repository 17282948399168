























import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase";

@Component({})
export default class StreamersList extends Vue {
  public streamers: string[] = [];

  async mounted() {
    this.streamers = (await firebase.database().ref("/streamers").get()).val();
  }
}
